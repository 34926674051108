<style lang="less">
.tComboCategoryList {
}
</style>
<template>
  <div class="tComboCategoryList">
    <Card>
      <Row type="flex" justify="space-between">
        <Col v-show="expand" span="5">
          <Alert show-icon>
            当前选择：
            <span class="select-title">{{ editTitle }}</span>
            <a class="select-clear" v-if="editTitle" @click="cancelEdit">取消选择</a>
          </Alert>
<!--          <Input v-model="searchKey" suffix="ios-search" @on-change="search" placeholder="输入搜索字典" clearable/>-->
          <div style="position: relative">
            <div class="tree-bar" :style="{ maxHeight: maxHeight }">
              <Tree
                  ref="tree"
                  :data="treeData"
                  @on-select-change="selectTree"
              ></Tree>
            </div>
            <Spin size="large" fix v-if="treeLoading"></Spin>
          </div>
        </Col>
<!--        <div class="expand">-->
<!--          <Icon :type="expandIcon" size="16" class="icon" @click="changeExpand"/>-->
<!--        </div>-->
        <Col :span="span">
<!--          <Row v-show="openSearch">-->
<!--            <Form ref="searchForm" :model="searchForm" inline :label-width="70" @keydown.enter.native="handSearch">-->
<!--              <Form-item style="margin-left:-35px" class="br">-->
<!--                <Button @click="handSearch" type="primary" icon="ios-search">搜索</Button>-->
<!--                <Button @click="handReset">重置</Button>-->
<!--                <a class="drop-down" @click="dropDown">-->
<!--                  {{ dropDownContent }}-->
<!--                  <Icon :type="dropDownIcon"></Icon>-->
<!--                </a>-->
<!--              </Form-item>-->
<!--            </Form>-->
<!--          </Row>-->
          <div class="operation">
            <Button @click="addTComboCategory" type="primary" icon="md-add">新增</Button>
            <Dropdown @on-click="handDropdown">
              <Button>
                更多操作
                <Icon type="md-arrow-dropdown"/>
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="refresh">
                  <Icon type="md-sync"/>
                  刷新
                </DropdownItem>
                <DropdownItem name="removeAll">
                  <Icon type="md-trash"/>
                  批量删除
                </DropdownItem>
                <DropdownItem name="export">
                  <Icon type="md-arrow-down"/>
                  导出本页数据
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Button type="warning" @click="handleSync" :loading="submitLoading" style="margin-right: 10px">
              <Icon type="md-edit"/>
              批量同步
            </Button>
<!--            <Button type="dashed" @click="openSearch=!openSearch">{{ openSearch ? '关闭搜索' : '开启搜索' }}</Button>-->
<!--            <Button type="dashed" @click="openTip=!openTip">{{ openTip ? '关闭提示' : '开启提示' }}</Button>-->
            <div style="float: right;">
              <Poptip transfer trigger="click" placement="bottom-end" title="动态列">
                <Button icon="md-list"></Button>
                <div slot="content" style="position:relative;min-height:5vh">
                  <Checkbox-group v-model="colOptions" @on-change="checkboxChange">
                    <checkbox v-for="item in colSelect" :label="item" :key="item"></checkbox>
                  </Checkbox-group>
                </div>
              </Poptip>
            </div>
          </div>
          <div v-show="openTip">
            <Alert show-icon>
              已选择
              <span class="select-count">{{ selectCount }}</span> 项
              <a class="select-clear" @click="clearSelectAll">清空</a>
              <span style="float: right;">点击右上角按钮配置动态列↑</span>
            </Alert>
          </div>
          <div>
            <Table :loading="loading" border :columns="columns" sortable="custom" :data="data"
                   @on-sort-change="changeSort" @on-selection-change="showSelect" ref="table">
              <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" @click="handLook(row, index)" size="small">
                  <Icon type="ios-eye-outline"/>
                  查看
                </Button>
                <Dropdown :transfer=true>
                  <Button size="small">
                    更多操作
                    <Icon type="md-arrow-dropdown"/>
                  </Button>
                  <DropdownMenu slot="list">
                    <DropdownItem name="edit" @click.native="handEdit(row, index)">
                      <Icon type="ios-create-outline"/>
                      修改
                    </DropdownItem>
                    <DropdownItem name="delete" @click.native="deleteData(row, index)">
                      <Icon type="md-trash"></Icon>
                      删除
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </template>
            </Table>
          </div>
          <Row type="flex" justify="end" class="page">
            <Page :current="searchForm.pageNumber" :total="total" :page-size="searchForm.pageSize"
                  @on-change="changePage" @on-page-size-change="changePageSize" :page-size-opts="[10,20,50]"
                  size="small" show-total show-elevator show-sizer></Page>
          </Row>
        </Col>
      </Row>
    </Card>
    <updateTComboCategory v-model="updateShow" :TComboCategoryId="TComboCategoryId" :modalTitle="title"
                          v-on:handSearch="handSearch"></updateTComboCategory>
  </div>
</template>
<script>
import {deleteTComboCategory, queryTComboCategoryList,getTComboCategoryParentList,syncComboCategory} from '@/api/healthy/tComboCategory'
import {getDictDataByType} from "@/api/index";
import updateTComboCategory from './updateTComboCategory'
export default {
  components: {
    updateTComboCategory
  },
  data() {
    return {
      drop: false,
      dropDownContent: "展开",
      dropDownIcon: "ios-arrow-down",
      search: '',
      data: [],
      columns: this.getTComboCategoryColumns(),
      openSearch: true,//打开搜索
      openTip: true,//打开提示
      loading: true, // 表单加载状态
      selectCount: 0, // 多选计数
      selectList: [], // 多选数据
      submitLoading: false,
      searchForm: {
        // 搜索框对应data对象
        pageNumber: 1, // 当前页数
        pageSize: 10, // 页面大小
        startDate: null,//开始时间
        endDate: null,//结束时间
        sort: 'createTime', // 默认排序字段
        order: 'desc', // 默认排序方式
        pid:''
      },
      total: 0,
      title: '',
      TComboCategoryId: '',
      updateShow: false,
      tableHeight: 200,
      colOptions: ["序号", "分类名称",  "检查类型", "封面图片","上级分类", "操作"],
      colSelect: ["序号", "分类名称",  "检查类型", "封面图片","上级分类",  "操作"],

      expand: true,
      span: 18,
      expandIcon: "ios-arrow-back",
      treeData: [], // 树数据
      searchKey: "", // 搜索树
      maxHeight: "500px",
      treeLoading: false, // 树加载状态
      editTitle: "", // 编辑节点名称
      selectNode: {},
      tComboCategoryForm: {
        id:'',
        categoryName:'',
        physicalTypeId:'',
        physicalTypeType:'',
        imageUrl:'',
        imageName:'',
        delFlag:'',
        createId:'',
        createTime:'',
        updateId:'',
        updateTime:'',
      },
    }
  },
  methods: {
    init() {
      // 获取树数据
      this.getAllDict();
      // 获取表单数据
      //this.getDataList();
    },
    getAllDict() {
      this.treeLoading = true;
      // getDictDataByType("checkType").then(res => {
      //   this.treeLoading = false;
      //   if (res.success) {
      //     //this.treeData = res.data;
      //     console.log(res.data)
      //   }
      // });

      getTComboCategoryParentList().then(res=>{
        this.treeLoading =false;

        if(res.success){
          let data = [];
          for (let i = 0; i < res.data.length; i++) {
            let obj = {...res.data[i]};
            obj.title = obj.categoryName;
            obj.expand = true;
            data.push(obj);
          }
          this.treeData = data;
        }
      })
    },
    selectTree(v) {
      if (v.length > 0) {
        // this.$refs.dictForm.resetFields();
        // 转换null为""
        for (let attr in v[0]) {
          if (v[0][attr] == null) {
            v[0][attr] = "";
          }
        }
        let str = JSON.stringify(v[0]);
        let data = JSON.parse(str);
        this.selectNode = data;
        this.tComboCategoryForm.pid = data.id
        this.editTitle = data.categoryName + "(" + data.id + ")";
        // 重新加载表
        this.searchForm.pageNumber = 1;
        this.searchForm.pageSize = 10;
        this.searchForm.pid = data.id;
        this.getDataList();
      } else {
        this.cancelEdit();
      }
    },
    cancelEdit() {
      let data = this.$refs.tree.getSelectedNodes()[0];
      if (data) {
        data.selected = false;
      }
      // 取消选择后获取全部数据
      this.selectNode = {};
      this.editTitle = "";
      this.getDataList();
    },
    //列表上方更多操作
    handDropdown(name) {
      if (name == "refresh") {
        this.getDataList();
      } else if (name == "removeAll") {
        this.delAll();
      } else if (name == "export") {
        let excolumns = this.columns.filter(function (v) {
          return v.title != "操作" && v.type != 'selection'
        });
        this.$refs.table.exportCsv({
          filename: '本页数据',
          columns: excolumns,
          data: this.data
        });
      }
    },
    //展开 收起
    dropDown() {
      if (this.drop) {
        this.dropDownContent = "展开";
        this.dropDownIcon = "ios-arrow-down";
      } else {
        this.dropDownContent = "收起";
        this.dropDownIcon = "ios-arrow-up";
      }
      this.drop = !this.drop;
    },
    //时间选择事件
    selectDateRange(v) {
      if (v) {
        this.searchForm.startDate = v[0];
        this.searchForm.endDate = v[1];
      }
    },
    //新增
    addTComboCategory() {
      this.title = '新增';
      this.updateShow = true
      this.TComboCategoryId = '';
    },
    //编辑
    handEdit(row, index) {
      this.title = '编辑';
      this.TComboCategoryId = row.id.toString();
      this.updateShow = true;
    },
    //查看
    handLook(row, index) {
      this.title = '查看';
      this.TComboCategoryId = row.id.toString();
      this.updateShow = true;
    },
    //分页查询
    getDataList() {
      this.loading = true;
      this.selectCount = 0;
      queryTComboCategoryList(this.searchForm).then(res => {
        if (res.success) {
          this.loading = false;
          this.data = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    //单一删除
    deleteData(row, index) {
      this.$Modal.confirm({
        title: "确认删除",
        content: "您确认要删除所点击选的数据?",
        loading: true,
        onOk: () => {
          this.userLoading = true;
          var ids = [];
          ids.push(row.id);
          this.patchDeleteData(ids);
        },
        onCancel: () => {
          this.$Message.info('取消了当前的操作行为！');
        },
      });
    },
    //批量删除
    delAll() {
      if (this.selectCount <= 0) {
        this.$Message.warning('您还未选择要删除的数据');
        return;
      }
      this.$Modal.confirm({
        title: '确认删除',
        content: '您确认要删除所选的 ' + this.selectCount + '条数据?',
        loading: true,
        onOk: () => {
          let ids = [];
          this.selectList.forEach(function (e) {
            ids.push(e.id);
          });
          this.patchDeleteData(ids);
        },
        onCancel: () => {
          this.$Message.info('取消了当前的操作行为！');
        }
      });
    },
    //删除（后台）
    patchDeleteData(ids) {
      if (ids == undefined || ids == null || ids.length == 0) {
        this.$Message.error('没有选择的数据');
        return;
      }
      deleteTComboCategory({ids: ids}).then(res => {
        this.userLoading = false;
        this.$Modal.remove();
        if (res.success) {
          this.modalTaskVisible = false;
          this.$Message.success('删除成功');
          this.getDataList();
        } else {
          this.$Message.error('删除失败');
        }
      });
    },
    //改变页码
    changePage(v) {
      this.searchForm.pageNumber = v;
      this.getDataList();
      this.clearSelectAll();
    },
    //改变每页显示数据的条数
    changePageSize(v) {
      this.searchForm.pageSize = v;
      this.getDataList();
    },
    //改变排序方式
    changeSort(e) {
      this.searchForm.sort = e.key;
      this.searchForm.order = e.order;
      if (e.order == 'normal') {
        this.searchForm.order = '';
      }
      this.getDataList();
    },
    //查询
    handSearch() {
      this.searchForm.pageNumber = 1;
      this.searchForm.pageSize = 10;
      this.getDataList();
    },
    //重置
    handReset() {
      this.$refs.searchForm.resetFields();
      this.searchForm.pageNumber = 1;
      this.searchForm.pageSize = 10;
      this.searchForm.startDate = null;
      this.searchForm.endDate = null;
      this.selectDate = null;
      // 重新加载数据
      this.getDataList();
    },
    //显示选择
    showSelect(e) {
      this.selectList = e;
      this.selectCount = e.length;
    },
    //清空选择
    clearSelectAll() {
      this.$refs.table.selectAll(false);
    },
    //获取列表字段
    getTComboCategoryColumns() {
      return [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '序号',
          align: 'center',
          minWidth: 60,
          ellipsis: true,
          tooltip: true,
          key: 'id',
          sortable: false
        },
        {
          title: '分类名称',
          align: 'center',
          minWidth: 120,
          ellipsis: true,
          tooltip: true,
          key: 'categoryName',
          sortable: false
        },
        {
          title: '检查类型',
          align: 'center',
          minWidth: 120,
          ellipsis: true,
          tooltip: true,
          key: 'physicalTypeId',
          sortable: false
        },
        {
          title: '检查类型',
          align: 'center',
          minWidth: 120,
          ellipsis: true,
          tooltip: true,
          key: 'physicalType',
          sortable: false
        },
        {
          title: '上级分类',
          align: 'center',
          minWidth: 120,
          ellipsis: true,
          tooltip: true,
          key: 'pid',
          sortable: false
        },
        {
          title: '封面图片',
          align: 'center',
          minWidth: 120,
          ellipsis: true,
          tooltip: true,
          key: 'imageName',
          sortable: false
        },
        // {
        //   title: '删除标识(0-未删除，1-已删除)',
        //   align: 'center',
        //   minWidth: 120,
        //   ellipsis: true,
        //   tooltip: true,
        //   key: 'delFlag',
        //   sortable: false
        // },
        // {
        //   title: '创建人',
        //   align: 'center',
        //   minWidth: 120,
        //   ellipsis: true,
        //   tooltip: true,
        //   key: 'createId',
        //   sortable: false
        // },
        // {
        //   title: '创建时间',
        //   key: 'createTime',
        //   sortable: true,
        //   align: 'center',
        //   width: 180,
        // },
        // {
        //   title: '修改人',
        //   align: 'center',
        //   minWidth: 120,
        //   ellipsis: true,
        //   tooltip: true,
        //   key: 'updateId',
        //   sortable: false
        // },
        // {
        //   title: '修改时间',
        //   key: 'updateTime',
        //   sortable: true,
        //   align: 'center',
        //   width: 180,
        // },
        {
          title: '操作',
          align: 'center',
          slot: 'action',
          width: 200,
        }
      ]
    },
    //动态列实现
    checkboxChange: function (data) {
      var columnss = this.getTSubstancesColumns();
      this.columns = columnss.filter(function (v) {
        return data.indexOf(v.title) > -1 || v.type == 'selection'
      })
    },
    changeExpand() {
      this.expand = !this.expand;
      if (this.expand) {
        this.expandIcon = "ios-arrow-back";
        this.span = 18;
      } else {
        this.expandIcon = "ios-arrow-forward";
        this.span = 23;
      }
    },
    //批量同步体检项目
    handleSync() {
      this.$Modal.confirm({
        title: '确认同步',
        content: '您确认要同步所有体检分类么?',
        loading: true,
        onOk: () => {
          this.submitLoading = true;

          syncComboCategory({}).then(res => {
            if (res.success) {
              //this.handleSearch();
              this.submitLoading = false;
              this.$Message.success('保存成功');
            }
          }).finally(() => {
            this.submitLoading = false;
            this.$Modal.remove()
          })

        },
        onCancel: () => {
          this.$Message.info('取消了当前的操作行为！');
        }
      });
    },
  },
  mounted() {
    this.getDataList();

    // 计算高度
    let height = document.documentElement.clientHeight;
    this.maxHeight = Number(height - 287) + "px";
    this.init();
  },
}
</script>
<style scoped="less">
.operation {
  margin-bottom: 10px;
}
</style>

